// jQuery.getScript('modules/shraddha/custom.js');
console.log('shraddha script file is running');

function onWhtsapClick(wts_id,wts_module,wts_pvalue,wts_name)
{

    $("#myModal").remove();

    var myKeyVals = { id : wts_id, module : wts_module, mob : wts_pvalue,name : wts_name};

    jQuery.ajax({

        url : 'index.php?entryPoint=WtsapIntegrationController',
        type : 'POST',
        data: myKeyVals,
        success : function (result) {

        $("#whtsap_icon").after('<div id="myModal" class="modal fade right" data-bs-backdrop="static" data-bs-keyboard="false"></div>');

        $("#myModal").html(result); 
        $("#myModal").modal('show');
        $(".modal-backdrop").hide();
        // $(".modal-dialog").draggable();
        whatsapp_chat(wts_pvalue);
        get_whatsapp_templates(wts_module);

         const chat_show_timer = window.setInterval(function() {
         whatsapp_chat(wts_pvalue);
         }, 7000);

         $(".close").click(function()
         {
            $("#myModal").html("");
            $("#myModal").modal('hide');
            clearInterval(chat_show_timer);
         });

        },
        error : function () {
           console.log ('error');
        }
   
      });
  
}

function whatsapp_chat(wts_pvalue)
{
   jQuery.ajax({
  
      url : 'index.php?entryPoint=WtsapChatIntegrationController',
      type : 'POST',
      async: true,
      data: { mob: wts_pvalue},
      success : function (res) {
          if(res)
          {
             $("#msg_card_body").html(res);
          }
          else
          {
             $("#msg_card_body").html("No data found");
          }
      },
      error : function () {
         console.log ('error');
      }
 
    });
}



function get_whatsapp_templates(wts_module)
{
   jQuery.ajax({
  
      url : 'index.php?entryPoint=WtsapGetTemplateController',
      type : 'POST',
      async: true,
      data: { module_name: wts_module},
      success : function (res) 
      {
         $("#template_dropdown").html(res);
         
      },
      error : function () {
         console.log ('error');
      }
 
    });
}





 
//    window.onload = function() {
//       var anchors = document.getElementsByTagName('a');
//       console.log(anchors);
//       const btn  = document.querySelector('a.dropdown-item.send-bulk-whatsapp-bulk-action.ng-star-inserted');

//       console.log(btn);
//       btn.onclick = function() {
//               alert('ho ho ho');
//       }
//        console.log(sugarListView.get_checks());

//   }
